import { create } from 'zustand';
import { Model } from '../@types/conversation';
import { useCallback, useMemo } from 'react';
import { GiPalette } from "react-icons/gi";

const MISTRAL_ENABLED: boolean = import.meta.env.VITE_APP_ENABLE_MISTRAL === 'true';
const availableModels: {
  modelId: Model;
  label: string;
  icon?: JSX.Element;
  supportMediaType: string[];
  badge?: "Beta" | "New" | "Old";
}[] = !MISTRAL_ENABLED ? [
  // {
  //   modelId: 'claude-instant-v1',
  //   label: 'Claude Instant',
  //   supportMediaType: [],
  // },
  // {
  //   modelId: 'claude-v2',
  //   label: 'Claude v2',
  //   supportMediaType: [],
  //   badge: "Old"
  // },
  {
    modelId: 'claude-v3.5-haiku',
    label: 'Claude 3.5 (Haiku)',
    supportMediaType: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  },
    // {
    //   modelId: 'claude-v3-haiku',
    //   label: 'Claude 3 (Haiku)',
    //   supportMediaType: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
    // },
    // {
    //   modelId: 'claude-v3-sonnet',
    //   label: 'Claude 3 (Sonnet)',
    //   supportMediaType: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
    // },
  {
    modelId: 'claude-v3.5-sonnet',
    label: 'Claude 3.5 (Sonnet)',
    supportMediaType: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  },
  {
    modelId: 'claude-v3-opus',
    label: 'Claude 3 (Opus)',
    supportMediaType: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
  },
  {
    modelId: 'amazon-nova-micro',
    label: 'Nova Micro',
    supportMediaType:[],
  },
  {
    modelId: 'amazon-nova-lite',
    label: 'Nova Lite',
    supportMediaType: ['image/jpeg', 'image/png',  'image/webp'],
  },
  {
    modelId: 'amazon-nova-pro',
    label: 'Nova Pro',
    supportMediaType: ['image/jpeg', 'image/png',  'image/webp'],
  },
  {
    modelId: 'amazon-nova-canvas',
    label: 'Nova Canvas',
    icon: GiPalette({}),
    supportMediaType:[],
  },
  {
    modelId: 'titan-image-gen-1',
    label: 'Titan Image',
    icon: GiPalette({}),
    supportMediaType: ['image/jpeg', 'image/png'],
  },
  {
    modelId: 'stability.sd3-large',
    label: 'SD-3L',
    icon: GiPalette({}),
    supportMediaType: ['image/jpeg', 'image/png'],
  },
  {
    modelId: 'stability.stable-image-core',
    label: 'SD_image_core',
    icon: GiPalette({}),
    supportMediaType: ['image/jpeg', 'image/png'],
  },
  {
    modelId: 'stability.stable-image-ultra',
    label: 'SD_image_ultra',
    icon: GiPalette({}),
    supportMediaType: ['image/jpeg', 'image/png'],
  },
] : [
  {
    modelId: 'mistral-7b-instruct',
    label: 'Mistral 7B',
    supportMediaType: [],
  },
  {
    modelId: 'mixtral-8x7b-instruct',
    label: 'Mixtral-8x7B',
    supportMediaType: [],
  },
  {
    modelId: 'mistral-large',
    label: 'Mistral Large',
    supportMediaType: [],
  },
]

const useModelState = create<{
  modelId: Model;
  setModelId: (m: Model) => void;
}>((set) => ({
  modelId: 'amazon-nova-micro',
  setModelId: (m) => {
    set({
      modelId: m,
    });
  },
}));

const useModel = () => {
  const { modelId, setModelId } = useModelState();

  const model = useMemo(() => {
    return availableModels.find((model) => model.modelId === modelId);
  }, [modelId]);

  const getModelLabel = useCallback((modelId: Model) => {
    return availableModels.find((model) => model.modelId === modelId)?.label
  }, [])

  return {
    modelId,
    setModelId,
    model,
    disabledImageUpload: (model?.supportMediaType.length ?? 0) === 0,
    acceptMediaType:
      model?.supportMediaType.flatMap((mediaType) => {
        const ext = mediaType.split('/')[1];
        return ext === 'jpeg' ? ['.jpg', '.jpeg'] : [`.${ext}`];
      }) ?? [],
    availableModels,
    getModelLabel,
  };
};

export default useModel;
